import { EVENTS } from "./structuredData/events"
import { EPICAWARDS } from "./structuredData/epicAwards"
import { NEWS } from "./structuredData/news"
import { PROGRAMS } from "./structuredData/programs"
import { SPECIALIZATIONS } from "./structuredData/specializations"
import { STORIES } from "./structuredData/stories"
import { GENERALINFO } from "./structuredData/generalInfo"
import { TEACHERS } from "./structuredData/teachers"
import { PUBLICATIONDETAIL } from "./structuredData/publicationDetail"
import { PROJECTS } from "./structuredData/projects"
import { STUDYPLANS } from "./structuredData/studyPlans"

export default {
  EPICAWARDS,
  EVENTS,
  GENERALINFO,
  NEWS,
  PROGRAMS,
  PROJECTS,
  PUBLICATIONDETAIL,
  SPECIALIZATIONS,
  STORIES,
  TEACHERS,

  ARTICLES: {
    title: "Articles",
    dataPacks: ["ARTICLES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "subtitle",
          title: "Subtitle",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "content",
          title: "Content",
          type: "RichText",
          html: true,
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Date",
          type: "DateField",
          mandatory: true,
          key: "dateTime",
          helptext: "This date will be used to sort in distributors and lists.",
        },
        {
          key: "linkText",
          title: "Link text",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          mandatory: true,
          advanced: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
          mandatory: true,
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category or Global Data sections to create as many as you need.",
          },
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
          showList: true,
          indexable: true,
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  BLOGS: {
    title: "Blogs Auto",
    dataPacks: ["BLOG"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: false,
    editable: false,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "image",
          title: "Image URL",
          type: "TextField",
        },
        {
          key: "published",
          title: "Published",
          type: "DateField",
          indexable: true,
        },
        {
          key: "categories",
          title: "Categories",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
        {
          key: "blogSource",
          title: "Blog Source",
          type: "AsyncCheckGroup",
          source: "BLOG_SOURCES",
        },
        {
          key: "blogTag",
          title: "Blog Tag",
          type: "AsyncCheckGroup",
          source: "BLOG_TAGS",
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  CLUBS: {
    title: "Clubs",
    dataPacks: ["OFFICES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: false,
    schema: {
      fields: [
        {
          key: "title",
          title: "City",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "subtitle",
          title: "Subtitle",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "phone",
          title: "Phone",
          type: "TextField",
        },
        {
          key: "email",
          title: "E-mail",
          type: "TextField",
        },
        {
          key: "latitude",
          title: "Location: Latitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "longitude",
          title: "Location: Longitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "linkText",
          title: "Link Text",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category section (Global) to create as many as you need.",
          },
        },
        {
          key: "regions",
          title: "Region",
          placeholder: "Select Region category",
          type: "MultiCheckSelect",
          source: "REGION",
        },
      ],
    },
    clone: null,
    defaultValues: {
      linkText: "Join the club",
    },
  },

  DURATION: {
    title: "Duration",
    dataPacks: ["SPECIALIZATIONS", "STUDIES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "code",
          title: "Code",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: true,
        },
        {
          key: "weight",
          title: "Weight",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
          indexable: true,
        },
      ],
    },
    clone: null,
    defaults: null,
  },

  EVENTS_AUTO: {
    title: "Events Auto",
    dataPacks: ["EVENTS"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: false,
    editable: false,
    schema: {
      fields: [
        {
          key: "guid",
          title: "Guid",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "dateTime",
          title: "Event date and time",
          type: "DateField",
          mandatory: true,
          indexable: true,
        },
        {
          key: "startDate",
          title: "Start Date",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "endDate",
          title: "End Date",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "abstract",
          title: "Abstract",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "address",
          title: "Address",
          type: "TextField",
        },
        {
          key: "format",
          title: "Format",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "place",
          title: "Place",
          type: "TextField",
        },
        {
          key: "city",
          title: "City",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "country",
          title: "Country",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "region",
          title: "Region",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          mandatory: true,
          advanced: true,
        },
        {
          key: "units",
          title: "Unit",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "pathways",
          title: "Pathways",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "regions",
          title: "Region",
          type: "MultiCheckSelect",
          source: "REGION",
        },
        {
          key: "formats",
          title: "Format",
          type: "MultiCheckSelect",
          source: "FORMAT",
        },
      ],
    },
    searchFrom: ["description", "place"],
    clone: null,
    defaultValues: null,
  },

  INSIGHTS: {
    title: "Insights Auto",
    dataPacks: ["INSIGHTS"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    editable: false,
    schema: {
      fields: [
        {
          key: "guid",
          title: "Guid",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "type",
          title: "Type",
          type: "Select",
          options: [
            {
              value: "articles",
              label: "Articles",
            },
            {
              value: "infographics",
              label: "Infographics",
            },
            {
              value: "podcasts",
              label: "Podcasts",
            },
            {
              value: "videos",
              label: "Videos",
            },
          ],
          mandatory: true,
        },
        {
          key: "insightDate",
          title: "Date",
          type: "DateField",
          mandatory: true,
          indexable: true,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "extract",
          title: "Extract",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "image",
          title: "Image URL",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
          mandatory: true,
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category or Global Data sections to create as many as you need.",
          },
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
    },
    searchFrom: ["title", "extract"],
    clone: null,
    defaultValues: null,
  },

  INTAKE: {
    title: "Intake",
    dataPacks: ["SPECIALIZATIONS", "STUDIES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "code",
          title: "Code",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: true,
        },
        {
          key: "weight",
          title: "Weight",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
          indexable: true,
        },
      ],
    },
    clone: null,
    defaults: null,
  },

  INTERNATIONAL_OFFICES: {
    title: "International Offices",
    dataPacks: ["OFFICES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: false,
    schema: {
      fields: [
        {
          key: "title",
          title: "City",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "address",
          title: "Address",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "phone",
          title: "Phone",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "email",
          title: "E-mail",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "latitude",
          title: "Location: Latitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "longitude",
          title: "Location: Longitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "linkText",
          title: "Link Text",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category section (Global) to create as many as you need.",
          },
        },
        {
          key: "regions",
          title: "Region",
          placeholder: "Select Region category",
          type: "MultiCheckSelect",
          source: "REGION",
          showList: true,
          indexable: true,
        },
      ],
    },
    clone: null,
    defaultValues: {
      linkText: "How to get there",
    },
  },

  LOCATION: {
    title: "Location",
    dataPacks: ["SPECIALIZATIONS", "STUDIES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "code",
          title: "Code",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: true,
        },
        {
          key: "weight",
          title: "Weight",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
          indexable: true,
        },
      ],
    },
    clone: null,
    defaults: null,
  },

  PARTNERS: {
    title: "Partners",
    dataPacks: ["OFFICES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: false,
    schema: {
      fields: [
        {
          key: "title",
          title: "Partner name",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "address",
          title: "Address",
          type: "TextField",
        },
        {
          key: "phone",
          title: "Phone",
          type: "TextField",
        },
        {
          key: "email",
          title: "E-mail",
          type: "TextField",
        },
        {
          key: "latitude",
          title: "Location: Latitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "longitude",
          title: "Location: Longitude",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "linkText",
          title: "Link Text",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category section (Global) to create as many as you need.",
          },
        },
        {
          key: "regions",
          title: "Region",
          placeholder: "Select Region category",
          type: "MultiCheckSelect",
          source: "REGION",
        },
      ],
    },
    clone: null,
    defaults: null,
  },

  PATHWAYS: {
    title: "Pathways",
    dataPacks: [
      "EPICAWARDS",
      "NEWS",
      "SPECIALIZATIONS",
      "STORIES",
      "STUDIES",
      "TEACHERS",
      "PUBLICATIONS",
      "EVENTS",
      "TESTIMONIALS",
      "ARTICLES",
      "PEOPLE",
      "PROJECTS",
      "SCHOLARSHIP",
      "AUDIOVISUAL",
    ],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  PATHWAYS_EXECUTIVE: {
    title: "Pathways executive education",
    dataPacks: ["SPECIALIZATIONS", "STUDIES"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  PEOPLE: {
    title: "People",
    dataPacks: ["PEOPLE"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Name",
          type: "TextField",
          mandatory: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
          mandatory: true,
        },
        {
          key: "position",
          title: "Position",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "linkedin",
          title: "Linkedin",
          type: "UrlField",
        },
        {
          key: "phone",
          title: "Phone",
          type: "TextField",
        },
        {
          key: "email",
          title: "Email",
          type: "TextField",
        },
        {
          key: "imageBackground",
          title: "imageBackground",
          type: "ImageField",
        },
        {
          key: "modalDetail",
          title: "Modal Detail",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category section (Global) to create as many as you need.",
          },
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "genders",
          title: "Gender",
          placeholder: "Select Gender category",
          type: "MultiCheckSelect",
          source: "GENDER",
        },
        {
          key: "regions",
          title: "Region",
          placeholder: "Select Region category",
          type: "MultiCheckSelect",
          source: "REGION",
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
          showList: true,
          indexable: true,
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
      searchFrom: [],
    },
    clone: null,
    defaultValues: null,
  },

  PODCASTS: {
    title: "Podcasts",
    dataPacks: ["AUDIOVISUAL"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "dateTime",
          title: "Date",
          type: "DateField",
          mandatory: true,
          indexable: true,
          helptext: "This date will be used to sort in distributors and lists.",
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
          mandatory: true,
          helptext: "Recommended use for thumbnail.",
        },
        {
          key: "iframePodcast",
          title: "iFrame Podcast",
          type: "RichText",
          mandatory: true,
        },
        {
          key: "isVideoPodcast",
          type: "UniqueCheck",
          title: "",
          options: [
            {
              title: "It is a video podcast",
            },
          ],
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "podcastType",
          title: "Podcast Type",
          placeholder: "Select Podcast Type",
          type: "MultiCheckSelect",
          source: "PODCAST_TYPE",
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
        },
      ],
    },
  },

  PUBLICATIONS: {
    title: "Publications",
    dataPacks: ["PUBLICATIONS"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "languageCard",
          title: "Language",
          type: "TextField",
          from: "languageCard",
          humanReadable: true,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "dateTime",
          title: "Date",
          type: "DateField",
          indexable: true,
        },
        {
          key: "author",
          title: "Author",
          type: "TextField",
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "linkText",
          title: "Link text",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          mandatory: true,
        },
        {
          key: "auxInfo",
          title: "Auxiliar Information",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category or Global Data sections to create as many as you need.",
          },
        },
        {
          key: "publicationType",
          title: "Publication type",
          placeholder: "Select Publication type category",
          type: "MultiCheckSelect",
          source: "PUBLICATION_TYPE",
          helptext: "Shown on the distributor card",
        },
        {
          key: "academicArea",
          title: "Academic area",
          placeholder: "Select Academic Area category",
          type: "MultiCheckSelect",
          source: "ACADEMIC_AREA",
          helptext: "Shown on the distributor card",
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
      searchFrom: ["author", "description", "auxInfo"],
    },
    clone: null,
    defaultValues: null,
  },

  SCHOLARSHIP: {
    title: "Scholarship",
    dataPacks: ["SCHOLARSHIP"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          html: true,
          validators: {
            maxChar: 100,
          },
          helptext: "Maximum 100 characters allowed",
          humanReadable: true,
        },
        {
          key: "linkText",
          title: "Link text",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "modalDivider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "MODAL DATA",
          },
        },
        {
          key: "modalTitle",
          title: "Title",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "modalDescription",
          title: "Description",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "modalLink",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category or Global Data sections to create as many as you need.",
          },
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "types",
          title: "Types",
          placeholder: "Select Scholarship Type category",
          type: "MultiCheckSelect",
          source: "SCHOLARSHIP_TYPE",
        },
      ],
      searchFrom: ["title", "description"],
    },
    clone: null,
    defaultValues: {
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor.",
      linkText: "More info",
      modalTitle: "Title",
      modalDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    },
  },

  SCHOOLS: {
    title: "Schools",
    dataPacks: [
      "EPICAWARDS",
      "NEWS",
      "STORIES",
      "TEACHERS",
      "SPECIALIZATIONS",
      "STUDIES",
      "PUBLICATIONS",
      "EVENTS",
      "TESTIMONIALS",
      "ARTICLES",
      "PEOPLE",
      "PROJECTS",
      "SCHOLARSHIP",
      "INSIGHTS",
      "AUDIOVISUAL",
    ],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },

        {
          title: "Logo",
          type: "Select",
          key: "school",
          options: [
            { value: "IE", label: "Instituto de empresa" },
            { value: "IENYC", label: "Instituto de empresa NYC" },
            { value: "SUMMERSCHOOL", label: "Summer school" },
            { value: "AL", label: "Alumni" },
            { value: "BUS", label: "Business School" },
            {
              value: "CLIC",
              label: "Center for Corporate Learning and Talent Management",
            },
            { value: "CGC", label: "Center for the Gobernance of Change" },
            { value: "GPC", label: "Global Policy Center" },
            { value: "CR", label: "Corporate Relations" },
            { value: "ENT", label: "Entrepreneurship" },
            { value: "XL", label: "Lifelong Learning" },
            { value: "LAW", label: "Law School" },
            { value: "ARQ", label: "School of Architecture and Design" },
            { value: "GPA", label: "School of Global and public Affairs" },
            {
              value: "SPEGA",
              label: "School of Politics, Economics & Global Affairs",
            },
            { value: "HMN", label: "Humanities School" },
            { value: "HST", label: "School of Science and Technology" },
            { value: "UNI", label: "University" },
            { value: "TC", label: "Talent & Career" },
            { value: "FOUND", label: "IE Foundation" },
            { value: "FAID", label: "Financial Aid" },
            {
              value: "LEAD",
              label: "Center Middle East",
            },
            { value: "SST50", label: "SST 50" },
            { value: "Architecture50", label: "Architecture 50" },
            { value: "Business50", label: "Business 50" },
            { value: "Law50", label: "Law 50" },
            { value: "Spega50", label: "SPEGA 50" },
            { value: "University50", label: "University 50" },
            { value: "LifelongLearning50", label: "LifelongLearning 50" },
            { value: "Humanities50", label: "Humanities 50" },
          ],
          mandatory: true,
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  STUDYPLANS,

  TESTIMONIALS: {
    title: "Testimonials",
    dataPacks: ["TESTIMONIALS"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          key: "quote",
          title: "Quote",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "name",
          title: "Author",
          type: "TextField",
          searchable: false,
        },
        {
          key: "jobTitle",
          title: "Job Title",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "linkText",
          title: "Link text",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "CATEGORIES",
            text: "Go to the Category or Global Data sections to create as many as you need.",
          },
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
          showList: true,
          indexable: true,
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "genders",
          title: "Gender",
          placeholder: "Select Gender category",
          type: "MultiCheckSelect",
          source: "GENDER",
        },
        {
          key: "stageTargets",
          title: "Stage Target",
          placeholder: "Select Stage Target category",
          type: "MultiCheckSelect",
          source: "STAGE_TARGET",
        },
        {
          key: "regions",
          title: "Region",
          placeholder: "Select Region category",
          type: "MultiCheckSelect",
          source: "REGION",
        },
        {
          key: "formats",
          title: "Format",
          placeholder: "Select Format category",
          type: "MultiCheckSelect",
          source: "FORMAT",
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },

  UNIT: {
    title: "Unit",
    dataPacks: [
      "EPICAWARDS",
      "NEWS",
      "SPECIALIZATIONS",
      "STORIES",
      "TEACHERS",
      "STUDIES",
      "PUBLICATIONS",
      "EVENTS",
      "TESTIMONIALS",
      "ARTICLES",
      "PEOPLE",
      "PROJECTS",
      "SCHOLARSHIP",
      "AUDIOVISUAL",
    ],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "weight",
          title: "Weight",
          type: "TextField",
          mandatory: true,
          searchable: false,
          humanReadable: false,
          indexable: true,
        },
      ],
    },
    clone: null,
    defaults: null,
  },

  VIDEOS: {
    title: "Videos",
    dataPacks: ["AUDIOVISUAL"],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "dateTime",
          title: "Date",
          type: "DateField",
          mandatory: true,
          indexable: true,
          helptext: "This date will be used to sort in distributors and lists.",
        },
        {
          key: "urlVideo",
          title: "Url Video",
          type: "TextField",
          mandatory: true,
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          title: "",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
          mandatory: true,
        },
        {
          key: "topics",
          title: "Topic",
          placeholder: "Select Topic category",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
        {
          key: "units",
          title: "Unit",
          placeholder: "Select Unit category",
          type: "MultiCheckSelect",
          source: "UNIT",
        },
        {
          key: "schools",
          title: "Schools",
          placeholder: "Select Schools category",
          type: "MultiCheckSelect",
          source: "SCHOOLS",
        },
        {
          key: "programs",
          title: "Programs",
          placeholder: "Select Programs category",
          type: "MultiCheckSelect",
          source: "PROGRAMS",
        },
        {
          key: "pathways",
          title: "Pathways",
          placeholder: "Select Pathways category",
          type: "MultiCheckSelect",
          source: "PATHWAYS",
        },
        {
          key: "subjectAreas",
          title: "Subject Area & Values",
          placeholder: "Select Subject Area & Values category",
          type: "MultiCheckSelect",
          source: "SUBJECT_AREA",
        },
        {
          key: "centers",
          title: "Centers & Departments",
          placeholder: "Select Centers & Departments category",
          type: "MultiCheckSelect",
          source: "CENTERS",
        },
      ],
    },

    clone: null,
    defaultValues: null,
  },
}
